import React, { Component } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import HeadingMain from '../components/HeadingMain'
import Quote from '../components/styles/Quote'
import ButtonArrow from '../components/ButtonArrow'
import Newsletter from '../components/Newsletter'
import SEO from '../components/SEO'

const Container = styled.div`
  max-width: ${p => p.theme.maxPageWidth};
  margin-left: auto;
  margin-right: auto;
  padding-left: ${p => p.theme.paddingOnSides};
  padding-right: ${p => p.theme.paddingOnSides};
  display: flex;
  margin-bottom: 90px;
  :nth-child(2),
  :nth-child(3) {
    margin-bottom: 20px;
  }
  @media (max-width: 1000px) {
    flex-wrap: wrap;
    padding-left: 0;
    padding-right: 0;
  }
`

const Left = styled.div`
  position: relative;
  width: 50%;
  padding-right: 80px;
  @media (max-width: 1000px) {
    width: 100%;
    padding-left: ${p => p.theme.paddingOnSides};
    padding-right: ${p => p.theme.paddingOnSides};
    margin-bottom: 30px;
  }
`

const Right = styled.div`
  width: 50%;
  @media (max-width: 1000px) {
    width: 100%;
    margin-top: 32px;
  }
`

const Heading = styled.h1`
  font-size: 30px;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  color: ${p => p.theme.textColorDark};
  margin-bottom: 24px;
`

const Paragraph = styled.p`
  margin-bottom: 26px;
`

const Portrait = styled(GatsbyImage)`
  width: 40vw;
  min-width: 100%;
  box-shadow: 0 12px 38px ${p => p.theme.lightGray};
  :first-child,
  :nth-child(2) {
    margin-bottom: 80px;
    @media (max-width: 1000px) {
      margin-bottom: 60px;
    }
    @media (max-width: 800px) {
      margin-bottom: 40px;
    }
  }
`

const WaterColor = styled(GatsbyImage)`
  position: absolute !important;
  width: 210px;
  opacity: 0.4;
  z-index: -1;
  top: -24px;
  left: -40px;
  @media (max-width: 1000px) {
    width: 170px;
    left: -5px;
    top: -20px;
  }
`

class Weingut extends Component {
  render() {
    const { portraitImage, houseImage, verkostungsraumImage, watercolorImage } = this.props.data
    return (
      <React.Fragment>
        <SEO
          title="Das Weingut"
          description="Familie wird beim Weingut Göschl groß geschrieben. Aktuell geführt in zweiter Generation, die dritte steht schon in den Startlöchern."
        />
        <HeadingMain mainHeading="Das Weingut" subHeading="Familie wird am Weingut Göschl großgeschrieben" />
        <Container>
          <Left>
            <WaterColor alt="" image={watercolorImage.childImageSharp.gatsbyImageData} />
            <Heading>Familien&shy;zusammenhalt - Familie & Betrieb vereint</Heading>
            <Paragraph>
              Der Familienbetrieb, der in Gols, einer der bedeutendsten Weinbaugemeinden Österreichs liegt, wird bereits
              in 3. Generation geführt.
            </Paragraph>
            <Paragraph>
              Georg Göschl begann in den 1950er Jahren Weingärten zu setzen und zu bewirtschaften sowie seine ersten
              Weine zu produzieren. Sein Sohn Reinhard ist mit dem Weinbau groß geworden und hat dann mit seiner Edith
              den Betrieb übernommen. Die beiden sind von Anfang an mit viel Leidenschaft, Engagement und Herz dabei.
            </Paragraph>
            <Paragraph>
              Nun hat die 3. Generation übernommen. Die Töchter Kathrin und Daniela wollen jetzt mit neuen Ideen
              aufmischen. Weingut Göschl und Töchter eben.
            </Paragraph>

            <Quote>Weingut Göschl und Töchter eben.</Quote>
          </Left>
          <Right>
            <Portrait
              critical={true}
              image={portraitImage.childImageSharp.gatsbyImageData}
              alt="Weingut Göschl - Familie"
            />
          </Right>
        </Container>
        <Container>
          <Left>
            <Paragraph>
              Unser Familienweingut bewirtschaftet knapp 12 ha Weingartenfläche. Wir arbeiten stets im Einklang mit der
              Natur und geerntet wird nur per Hand, natürlich mit Zwicker und Scheibtruhen. Im Sortiment finden sich
              sowohl frische, fruchtige Weiße, wie Welschriesling, Chardonnay oder Pinot Gris, als auch kräftige und
              facettenreiche Rote, wie unser Aushängeschild der Zweigelt Ried Goldberg, und auch Cuvée Ungerberg oder
              Blaufränkisch Ried Prädium.
            </Paragraph>
            <Quote>Geerntet wird nur per Hand, natürlich mit Zwicker und Scheibtruhen.</Quote>
            <Paragraph>
              Unsere Weine werden alle mit viel Liebe und Gefühl von Reinhard und Daniela vinifiziert. Die frischen
              Weißweine werden in Edelstahltanks ausgebaut, während die kräftigen Rotweine ihren Weg ins Barriquefass
              finden und es sich dort auch gerne mal ein Jahr oder länger gemütlich machen, bevor es in die Flasche und
              zu den Kunden weiter geht. Hie und da wird auch mal ein Weißer ins Barrique verfrachtet. Für ein extra
              Prickeln sorgen die Frizzante – erhältlich als Riesling, Muskateller oder Rosé. Seit Jahrzehnten sind wir
              stets darum bemüht, unsere Kundinnen und Kunden mit bester Qualität zu verwöhnen.
            </Paragraph>
          </Left>
          <Right>
            <Portrait
              critical={true}
              image={houseImage.childImageSharp.gatsbyImageData}
              alt="Weingut Göschl - Betrieb"
            />
          </Right>
        </Container>
        <Container>
          <Left>
            <Paragraph>
              Neben dem klassischen Sortiment des Weinguts, gehen die Weine der Töchter in eine neue Richtung: naturnahe
              Handarbeit im Weingarten, Bewusstes Reduzieren und Weglassen im Keller stehen im Vordergrund. Der Wein
              darf sich entwickeln wie er ist, jeder Jahrgang anders und einzigartig. Diese Naturweine dürfen Namen aus
              dem Yoga tragen, denn Daniela ist nebenberuflich leidenschaftliche Yogalehrerin und bietet am Weingut auch
              Yogakurse, Yoga & Weinverkostungen sowie Yoga & Wein Retreats an. In diesem Sinne: Namasté und Prost.
            </Paragraph>
            <ButtonArrow link="/Weine/" text="Zu unserem Weinsortiment" />
          </Left>
          <Right>
            <Portrait
              critical={true}
              image={verkostungsraumImage.childImageSharp.gatsbyImageData}
              alt="Weingut Göschl - Verkostungsraum"
            />
          </Right>
        </Container>
        <Newsletter />
      </React.Fragment>
    )
  }
}

export default Weingut

export const query = graphql`
  {
    portraitImage: file(relativePath: { eq: "photos/weingut-goeschl-weingut-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    houseImage: file(relativePath: { eq: "photos/weingut-goeschl-weingut-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    verkostungsraumImage: file(relativePath: { eq: "photos/verkostungsraum.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    watercolorImage: file(relativePath: { eq: "photos/background-watercolor.png" }) {
      childImageSharp {
        gatsbyImageData(width: 190, quality: 80, placeholder: NONE, layout: CONSTRAINED)
      }
    }
  }
`
